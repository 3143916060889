.landing {
    font-family: 'Arial', sans-serif;
    background: #f7f9fc;
    padding: 20px;
  }
  
  .landing-header {
    background: #0f4c75;
    color: #ffffff;
    text-align: center;
    padding: 20px 0;
    border-radius: 5px;
    margin-bottom: 40px;
  }
  
  .landing-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .landing-header p {
    font-size: 1.1rem;
  }
  
  .tool-section {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
  }
  
  .tool-section h2 {
    border-bottom: 2px solid #0f4c75;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  li {
    padding: 8px 0;
  }
  